import classes from "./ContactCard.module.css";

interface ContactCardProps {
  link: string;
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
  className?: string;
  delay?: number;
}

export default function ContactCard(props: ContactCardProps) {
  return (
    <a
      href={props.link}
      className={`${classes.card} ${props.className ? props.className : ""}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={classes.triangle} />
      <span className={classes.title}>{props.title}</span>
      {props.subtitle && <p>{props.subtitle}</p>}
      <span className={classes.icon}>{props.icon}</span>
    </a>
  );
}
