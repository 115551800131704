import { motion } from "framer-motion";
import classes from "./Projects.module.scss";

export default function Projects() {
  return (
    <motion.div
      initial={{ y: "-100vh" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
      className={classes.container}
    >
      <div className={classes.projects}>
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className={classes.project}
        >
          <h1>Configuration Management Database for the University of Utah</h1>
          <img
            src="/utahLogo.png"
            alt="University of Utah logo"
            className={classes.utah}
          />
          <h4>
            A fullstack React application with an ExpressJS backend connected to Microsoft SQL Server. This Configuration Management Application runs a portal for the internal
            University IT department with over 1,000,000 instances of data tracking.
          </h4>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.9 }}
          className={classes.project}
        >
          <h1>Property Management User/Admin Portal</h1>
          <img src="/live-in.png" alt="Live In" className={classes.liveIn} />
          <h4>
            A portal built with a React frontend and C# backend. Utilizes the Google Cloud infrastructure and a Cloud SQL database to store user and property data. Set to release fall 2022.
          </h4>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1.3 }}
          className={classes.project}
        >
          <h1>Anonymous Chat Application</h1>
          <img src="/anon-chat.jpeg" alt="Anonymous Chat" className={classes.anonChat} />
          <h4>
            A simple chat application that pairs you with a random other user. Built with Ionic, Angular, and Firebase.
          </h4>
        </motion.div>
      </div>
    </motion.div>
  );
}
