import { useEffect } from "react";
import { motion } from "framer-motion";
import { TbWorld } from "react-icons/tb";
import { IoIosPhonePortrait } from "react-icons/io";
import { AiOutlineDatabase } from "react-icons/ai";
import { IoIosCloudDone } from "react-icons/io";
import InfoCard from "../components/ui/InfoCard";
import classes from "./About.module.css";

export default function About() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.container}>
      <motion.img
        src="/JClogo.svg"
        alt="JC logo"
        className={classes.logo}
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      />
      <motion.h1
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={classes.title}
      >
        Jason Crandall
      </motion.h1>
      <motion.hr
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 0.5 }}
        className={classes.hr}
      />
      <motion.div className={classes.aboutParagraph}>
        <motion.img
          src="/profile.jpeg"
          alt="Jason Crandall"
          className={classes.profileImg}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0, transition: { duration: 0.5 } }}
        />
        <motion.p
          initial={{ opacity: 0, x: -25 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { duration: 0.5, delay: 0.2 },
          }}
        >
          Ever since I began programming, quality has always been my top
          priority. My mission for every application that I develop is to make
          quality, interactive products that are easily accessible for every
          user.
        </motion.p>
      </motion.div>
      <motion.hr
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 0.5 }}
        className={classes.hr}
      />
      <br />
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.6 }}
        className={classes.placeholder}
      />
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.8 }}
        className={classes.placeholder}
      />
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 1 }}
        className={classes.placeholder}
      />
      <div className={classes.skills}>
        <InfoCard
          title="Web"
          icon={<TbWorld />}
          direction="left"
          className={classes.cardLeft}
        >
          From wireframes to full-fledged web applications, I have the ability
          to create anything from simple websites to complex, interactive web
          portals.
        </InfoCard>
        <motion.hr
          initial={{ scaleX: 0 }}
          whileInView={{ scaleX: 1}}
          transition={{ duration: 0.5, delay: 0.5 }}
          viewport={{ once: true }}
          className={classes.hr}
        />
        <InfoCard
          title="Mobile"
          icon={<IoIosPhonePortrait />}
          direction="right"
          className={classes.cardRight}
        >
          Using the Ionic Framework, I can utilize the capabilities of web
          technologies to create seamless cross-platform mobile applications.
        </InfoCard>
        <motion.hr
          initial={{ scaleX: 0 }}
          whileInView={{ scaleX: 1}}
          transition={{ duration: 0.5, delay: 0.5 }}
          viewport={{ once: true }}
          className={classes.hr}
        />
        <InfoCard
          title="Database"
          icon={<AiOutlineDatabase />}
          direction="left"
          className={classes.cardLeft}
        >
          With experience in both NoSQL and SQL databases, I can design, build,
          and maintain databases that are both scalable and easily integratable
          with other systems.
        </InfoCard>
        <motion.hr
          initial={{ scaleX: 0 }}
          whileInView={{ scaleX: 1}}
          transition={{ duration: 0.5, delay: 0.5 }}
          viewport={{ once: true }}
          className={classes.hr}
        />
        <InfoCard
          title="Cloud"
          icon={<IoIosCloudDone />}
          direction="right"
          className={classes.cardRight}
        >
          Integrating an application into a cloud environment can be a daunting
          task, but I can help you get there. With a background in Azure and the
          Google Cloud Platform, I can help you deploy your application to a
          secure and production ready infrastructure.
        </InfoCard>
      </div>
    </div>
  );
}
