import { Fragment } from "react";
import MenuBar from "./components/layout/MenuBar";
import { Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Technologies from "./pages/Technologies";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import classes from "./App.module.css";

function App() {
  return (
    <Fragment>
      <MenuBar />
      <div className={classes.main}>
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/technologies" element={<Technologies />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<h1>404</h1>} />
        </Routes>
      </div>
    </Fragment>
  );
}

export default App;
