import { motion } from "framer-motion";
import ContactCard from "../components/ui/ContactCard";
import { AiFillMail, AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import { SiUpwork } from "react-icons/si";
import classes from "./Contact.module.css";

const CONTACTS = [
  {
    name: "Email",
    icon: <AiFillMail />,
    link: "mailto:jason.crandall.dev@gmail.com",
  },
  {
    name: "LinkedIn",
    icon: <AiFillLinkedin />,
    link: "https://www.linkedin.com/in/jason-crandall/",
  },
  {
    name: "Github",
    icon: <AiFillGithub />,
    link: "https://www.github.com/JasonDCrandall",
  },
  {
    name: "Upwork",
    icon: <SiUpwork />,
    link: "https://www.upwork.com/freelancers/~0140135d37f8a9ec5f",
  },
];

const variants = {
  visible: (i: number) => ({
    y: [-100, 20, 0],
    opacity: 1,
    transition: {
      delay: (i * 0.4) + 0.7,
      duration: 0.4,
      ease: "easeInOut",
    },
  }),
  hover: {
    scale: 1.1,
  },
  tap: {
    scale: 0.9,
  },
  hidden: {
    y: "-50vh",
    opacity: 0,
  },
};

export default function Contact() {
  return (
    <div className={classes.container}>
      <motion.h2
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2, delay: 0.5 }}
      >
        Here are some platforms to find out more about me. Feel free to reach
        out!
      </motion.h2>
      {CONTACTS.map((contact, i) => (
        <motion.div
          custom={i}
          key={contact.name}
          variants={variants}
          initial="hidden"
          animate="visible"
          whileHover={"hover"}
          whileTap={"tap"}
          className={classes.card}
        >
          <ContactCard
            title={contact.name}
            icon={contact.icon}
            link={contact.link}
          />
        </motion.div>
      ))}
    </div>
  );
}
