import { PureComponent, useState } from "react";
import { motion } from "framer-motion";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import classes from "./CustomPie.module.css";

// const data = [
//   { name: "Group A", value: 400 },
//   { name: "Group B", value: 300 },
//   { name: "Group C", value: 300 },
//   { name: "Group D", value: 200 },
// ];

const OPAL = 'var(--opal)';

interface CustomPieProps {
  cx: number;
  cy: number;
  innerRadius: number;
  midAngle: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  payload: any;
  percent: number;
  value: string;
  data: any;
}

const renderActiveShape = (props: CustomPieProps) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    payload,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={OPAL} className={classes.centerTitle}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={OPAL}
      />
      <motion.g initial={{ scale: 0 }} animate={{ scale: 1 }}>
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={OPAL}
        />
      </motion.g>
      <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={OPAL}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={OPAL} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill={OPAL}
        >{`${value}%`}</text>
      </motion.g>
    </g>
  );
};

interface CPieProps {
  pieData: { name: string; value: number; }[];
  handleActive?: (index: number) => void
}

export default function CustomPie(props: CPieProps) {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
    if (props.handleActive) {
      props.handleActive(index);
    }
  };

    return (
      <motion.div
        initial={{ scale: 1.1 }}
        className={classes.pieContainer}
      >
        <text className={classes.callToAction}>Hover for Details</text>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={props.pieData}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="var(--fire)"
              dataKey="value"
              onMouseEnter={onPieEnter}
            />
          </PieChart>
        </ResponsiveContainer>
      </motion.div>
    );
}
