import { Fragment, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import MenuBarItem from "./MenuBarItem";
import classes from "./MenuBar.module.css";
import { HiMenu } from "react-icons/hi";
import { useNavigate, useLocation } from "react-router-dom";

export default function MenuBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [smallMenu, setSmallMenu] = useState(window.innerWidth < 650);
  const [toggle, setToggle] = useState(false);
  const [path, setPath] = useState(location.pathname);

  function handleSelect(event: any, path: string) {
    event.preventDefault();
    navigate(path);
    setToggle(false);
  }

  const updateWindow = () => {
    setSmallMenu(window.innerWidth < 650);
  };

  useEffect(() => {
    setPath(location.pathname);
    window.addEventListener("resize", updateWindow);
    return () => {
      window.removeEventListener("resize", updateWindow);
    };
  }, [location]);

  return (
    <motion.div
      initial={{ y: -120 }}
      animate={{ y: -60 }}
      transition={{ duration: 0.5 }}
      className={`${classes.bar} ${smallMenu ? classes.small : ""}`}
    >
      {smallMenu ? (
        <Fragment>
          <span className={classes.smallTitle}>JC Development</span>
          <motion.div
            className={classes.menuIcon}
            initial={{ backgroundColor: "var(--rich-black)" }}
            whileHover={{
              backgroundColor: "var(--eton-blue)",
              color: "var(--rich-black)",
            }}
            transition={{ duration: 0.1 }}
            onClick={() => setToggle(!toggle)}
          >
            <HiMenu />
          </motion.div>
          <AnimatePresence>
            {toggle && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: "fit-content" }}
                transition={{ duration: 0.2 }}
                exit={{ height: 0 }}
                className={classes.menu}
              >
                <MenuBarItem
                  text="About"
                  onClick={(event) => {
                    handleSelect(event, "/");
                  }}
                  active={path === "/"}
                />
                <MenuBarItem
                  text="Technologies"
                  onClick={(event) => {
                    handleSelect(event, "/technologies");
                  }}
                  active={path === "/technologies"}
                />
                <MenuBarItem
                  text="Projects"
                  onClick={(event) => {
                    handleSelect(event, "/projects");
                  }}
                  active={path === "/projects"}
                />
                <MenuBarItem
                  text="Contact"
                  onClick={(event) => {
                    handleSelect(event, "/contact");
                  }}
                  active={path === "/contact"}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </Fragment>
      ) : (
        <Fragment>
          <MenuBarItem
            text="About"
            onClick={() => {
              navigate("/");
            }}
            active={path === "/" ? true : false}
          />
          <MenuBarItem
            text="Technologies"
            onClick={() => {
              navigate("/technologies");
            }}
            active={path === "/technologies" ? true : false}
          />
          <MenuBarItem
            text="Projects"
            onClick={() => {
              navigate("/projects");
            }}
            active={path === "/projects" ? true : false}
          />
          <MenuBarItem
            text="Contact"
            onClick={() => {
              navigate("/contact");
            }}
            active={path === "/contact" ? true : false}
          />
        </Fragment>
      )}
    </motion.div>
  );
}
