import { useEffect } from "react";
import { motion } from "framer-motion";
import classes from "./MenuBarItem.module.css";

interface MenuBarItemProps {
  text: string;
  onClick: (event: any) => void;
  active?: boolean;
}



export default function MenuBarItem(props: MenuBarItemProps) {
  const hoverMotion = {
    hover: {
      scale: 1,
      backgroundColor: "var(--fire)",
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
    initial: {
      scale: 0,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
    active: {
      scale: 1,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    }
  };
  return (
    <motion.div
      className={classes.item}
      onClick={props.onClick}
      initial="initial"
      whileHover="hover"
      animate={`${props.active ? "active" : "initial"}`}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
    >
      <span className={classes.text}>{props.text}</span>
      <motion.div variants={hoverMotion} className={classes.underline} />
    </motion.div>
  );
}
