import { motion } from "framer-motion";
import classes from "./InfoCard.module.css";

interface InfoCardProps {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  direction: "left" | "right";
}

export default function InfoCard(props: InfoCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, x: props.direction === "left" ? -100 : 100 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5, delay: 0.5 }}
      viewport={{ once: true }}
      className={
        `${classes.card} ${
          props.direction === "right" ? classes.right : classes.left
        }` + (props.className ? ` ${props.className}` : "")
      }
    >
      <div
        className={`${classes.iconContainer} ${
          props.direction === "right" ? classes.icRight : classes.icLeft
        }`}
      >
        <span className={classes.icon}>{props.icon}</span>
      </div>
      <div
        className={`${classes.cardHeader} ${
          props.direction === "right" ? classes.right : classes.left
        }`}
      >
        {props.title}
      </div>
      <div className={classes.cardBody}>{props.children}</div>
    </motion.div>
  );
}
