import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import CustomPie from "../components/ui/CustomPieGraph/CustomPie";
import classes from "./Technologies.module.css";

const frameworkData = [
  { name: "React", value: 40 },
  { name: "NextJS", value: 30 },
  { name: "Ionic", value: 15 },
  { name: "Express", value: 10 },
  { name: "Angular", value: 5 },
];

const frameworkDescriptions = {
  React:
    "A JavaScript/TypeScript frontend library for building web applications and user interfaces. This is my most familiar framework and I typically use it jointly wth other tools.",
  NextJS:
    "A JavaScript/TypeScript fullstack framework for building web applications. I often use this framework when dealing with server side rendering, or applications requiring extensive routing.",
  Ionic:
    "A TypeScript library for building native mobile applications. It utilizes either React or Angular as the base framework, and is my prefered method for developing both progressive web applications and native mobile applications.",
  Express:
    "A JavaScript backend framework for building web applications. This is the backend framework I use when building REST APIs for standalone React applications.",
  Angular:
    "A TypeScirpt library for building full stack web applications. I use it for building single page applications that require multiple global services.",
};

const languageData = [
  { name: "TypeScript", value: 50 },
  { name: "Python", value: 30 },
  { name: "C#", value: 20 },
];

const languageDescriptions = {
  TypeScript:
    "A JavaScript superset that adds more readabilty and type security to code. This is the main language I use for writing server side code and for writing TypeScript based React components and frontends.",
  Python:
    "A general-purpose, high-level programming language. As I normally use other JavaScript/TypeScript for web backends, I leverage Python for infrastructure automation and scripting",
  CSharp:
    "A general-purpose, object-oriented programming language. I use it for writing server side code and for writing C# based ASP.NET MVC applications.",
};

const databaseData = [
  { name: "MySQL", value: 50 },
  { name: "MSSQL", value: 20 },
  { name: "Firebase", value: 20 },
  { name: "MongoDB", value: 10 },
];

const databaseDescriptions = {
  MySQL:
    "A relational database management system. This is the database I favor for smaller scale applications that require a relational database.",
  MSSQL:
    "Microsoft SQL Server is a relational database management system with more in-depth functionality. I use this system for larger scale applications that require a significat amount of data.",
  Firebase:
    "A cloud database management system that is extremely fast and reliable. As this is the most flexible option, I use this system when building cross platform applications that require a database, or for hosting both authentication and data storage in one place.",
  MongoDB:
    "A NoSQL database management system. Though I am familiar with this system, I mostly use it if NoSQL is required and I'm doing just local testing.",
};

export default function Technologies() {
  const [activeFrameworkText, setActiveFrameworkText] = useState<string | null>(
    frameworkDescriptions.React
  );

  const [activeLanguageText, setActiveLanguageText] = useState<string | null>(
    languageDescriptions.TypeScript
  );

  const [activeDatabaseText, setActiveDatabaseText] = useState<string | null>(
    databaseDescriptions.MySQL
  );

  const handleFrameworkClick = (index: number) => {
    setActiveFrameworkText(null);
    switch (index) {
      case 0:
        setActiveFrameworkText(frameworkDescriptions.React);
        break;
      case 1:
        setActiveFrameworkText(frameworkDescriptions.NextJS);
        break;
      case 2:
        setActiveFrameworkText(frameworkDescriptions.Ionic);
        break;
      case 3:
        setActiveFrameworkText(frameworkDescriptions.Express);
        break;
      case 4:
        setActiveFrameworkText(frameworkDescriptions.Angular);
        break;
      default:
        break;
    }
  };

  const handleLanguageClick = (index: number) => {
    switch (index) {
      case 0:
        setActiveLanguageText(languageDescriptions.TypeScript);
        break;
      case 1:
        setActiveLanguageText(languageDescriptions.Python);
        break;
      case 2:
        setActiveLanguageText(languageDescriptions.CSharp);
        break;
      default:
        break;
    }
  };

  const handleDatabaseClick = (index: number) => {
    switch (index) {
      case 0:
        setActiveDatabaseText(databaseDescriptions.MySQL);
        break;
      case 1:
        setActiveDatabaseText(databaseDescriptions.MSSQL);
        break;
      case 2:
        setActiveDatabaseText(databaseDescriptions.Firebase);
        break;
      case 3:
        setActiveDatabaseText(databaseDescriptions.MongoDB);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.container}>
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={classes.title}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Frameworks
      </motion.span>
      <div className={classes.pieInfo + " " + classes.left}>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className={classes.pie}
        >
          <CustomPie
            pieData={frameworkData}
            handleActive={handleFrameworkClick}
          />
        </motion.div>
        <motion.span
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2, delay: 0.5 }}
          className={classes.description}
        >
          {activeFrameworkText
            ? activeFrameworkText
            : "Click on a slice to see more info"}
        </motion.span>
      </div>
      <motion.hr
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className={classes.hr}
      />
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className={classes.title}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Languages
      </motion.span>
      <div className={classes.pieInfo + " " + classes.right}>
        <motion.span
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2, delay: 0.7 }}
          className={classes.description}
        >
          {activeLanguageText
            ? activeLanguageText
            : "Click on a slice to see more info"}
        </motion.span>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className={classes.pie}
        >
          <CustomPie
            pieData={languageData}
            handleActive={handleLanguageClick}
          />
        </motion.div>
      </div>
      <motion.hr
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className={classes.hr}
      />
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className={classes.title}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Databases
      </motion.span>
      <div className={classes.pieInfo + " " + classes.left}>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className={classes.pieInfo + " " + classes.left}
        >
          <CustomPie
            pieData={databaseData}
            handleActive={handleDatabaseClick}
          />
        </motion.div>
        <motion.span
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2, delay: 0.9 }}
          className={classes.description}
        >
          {activeDatabaseText
            ? activeDatabaseText
            : "Click on a slice to see more info"}
        </motion.span>
      </div>
    </div>
  );
}
